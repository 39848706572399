import { LogoJsonLd, NextSeo } from 'next-seo';

import { getDirection } from './utils';

export const getBasicMetaTags = (
    metaTitle: string,
    metaDescription: string,
    metaImage: string,
): React.ReactElement[] | null => {
    const direction = getDirection();
    const locale = direction === 'rtl' ? 'ar' : 'en';

    const jsonLD = [];
    const defaultImage = metaImage;
    const baseURL = `https://advertise.noon.com/`;
    jsonLD.push(<LogoJsonLd key="next-seo-logo" logo={defaultImage} url={`${baseURL}/`} />);

    const basicSEO = (
        <NextSeo
            key="next-seo-basic"
            title={metaTitle}
            description={metaDescription}
            openGraph={{
                title: metaTitle,
                description: metaDescription,
                images: [
                    {
                        url: defaultImage,
                        width: 1200,
                        height: 628,
                    },
                ],
                locale,
                site_name: `advertise.noon.com`,
                type: 'website',
                url: 'https://advertise.noon.com',
            }}
            twitter={{
                site: 'advertise.noon.com',
                handle: 'advertise.noon.com',
            }}
        />
    );

    return [basicSEO, ...jsonLD];
};

// All about SEO meta data
export const mainMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const advertiseMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const brandMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const brandsMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const displayMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const productMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const requestMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};

export const sellersMetaData = {
    title: 'noon ads: one-stop shop for leading ads solutions',
    description: 'Welcome to the noon ads ',
    og_title: 'noon ads: one-stop shop for leading ads solutions',
    og_type: 'Website',
    og_url: 'https://advertise.noon.com',
    og_desc: 'Welcome to the noon ads ',
    og_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_card: '',
    twr_title: 'noon ads: one-stop shop for leading ads solutions',
    twr_type: 'website',
    twr_desc: 'Welcome to the noon ads ',
    twr_image:
        'https://storage.googleapis.com/noon-cdn/s/app/com/noon/images/product-ads/adtech-comms-emailer/ads_thumbnail.png',
    twr_url: 'https://advertise.noon.com',
    google: 'W-ir9NW82gSCWFTTC7tIitAQ5GXqTaZW1z4Gxpd74xk',
};
