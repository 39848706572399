import { App, ConfigProvider } from 'antd';
import { appWithTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import Theme from '@mp-ads/noon-advertise/src/styles/theme';

import AppLayout from '../components/AppLayout';
import { getBasicMetaTags, mainMetaData } from '../metaHelper';
import '../styles/global.css';
import { appInit } from '../utils';

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const isRtl = appInit(router);

    const metaData = getBasicMetaTags(mainMetaData.title, mainMetaData.description, mainMetaData.og_image);

    try {
        return (
            <ConfigProvider direction={isRtl} theme={{ ...Theme }}>
                <App>
                    {metaData}
                    <AppLayout>
                        <Component props={{ ...pageProps, isRtl: isRtl === 'rtl' }} />
                    </AppLayout>
                </App>
            </ConfigProvider>
        );
    } catch (error) {
        // eslint-disable-next-line no-console
        return <div>Error!</div>;
    }
}

export const getServerSideProps = async ({ locale }): Promise<any> => ({
    props: {
        ...(await serverSideTranslations(locale, ['advertise'])),
        meta_data: mainMetaData,
    },
});

export default appWithTranslation(MyApp);
