import { ErrorInfo } from 'react';

import * as Sentry from '@sentry/nextjs';

import { IS_DOCKER } from '../common/baseConfig';

// Configure function arg type
export type TConfigureScopeData = {
    id: number;
    email: string;
};

type TSeverity = 'critical' | 'error' | 'warning' | 'info' | 'debug';

/* CAPTURE FUNCTIONS */
export const configureScope = (data: TConfigureScopeData): void => {
    Sentry.configureScope((scope: Sentry.Scope) => {
        Object.keys(data).forEach((key: string) => {
            scope.setExtra(key, data[key as keyof TConfigureScopeData]);
        });
    });
    if (!IS_DOCKER) {
        // eslint-disable-next-line no-console
        console.log('user', data);
    }
};

export const captureBreadcrumb = (category: string, title: string, data: Record<string, any>): void => {
    Sentry.addBreadcrumb({
        category,
        message: title,
        data: typeof data === 'string' ? { data } : data,
    });

    if (!IS_DOCKER) {
        // eslint-disable-next-line no-console
        console.log(title, typeof data === 'string' ? { data } : data);
    }
};

export const reportError = (error: Error, errorInfo?: ErrorInfo): void => {
    Sentry.withScope((scope: Sentry.Scope) => {
        if (errorInfo) {
            Object.keys(errorInfo).forEach((key: string) => {
                scope.setExtra(key, errorInfo[key as keyof ErrorInfo]);
            });
        }

        Sentry.captureException(error);
    });

    if (!IS_DOCKER) {
        // eslint-disable-next-line no-console
        console.log(error, errorInfo);
    }
};

export const reportEvent = (
    msg: string,
    logger: string,
    severity?: TSeverity,
    extra?: Record<string, any> | string,
): void => {
    let level: Sentry.Severity;
    let message = msg;

    switch (severity) {
        case 'critical':
            message = `☢️ ${message}`;
            level = Sentry.Severity.Critical;
            break;
        case 'error':
            level = Sentry.Severity.Error;
            break;
        case 'warning':
            level = Sentry.Severity.Warning;
            break;
        case 'info':
            level = Sentry.Severity.Info;
            break;
        case 'debug':
            level = Sentry.Severity.Debug;
            break;
        default:
            level = Sentry.Severity.Error;
    }

    Sentry.captureEvent({ message, extra: { extra, logger }, level });

    if (!IS_DOCKER) {
        if (extra) {
            // eslint-disable-next-line no-console
            console.warn(message, ` | at ${logger}`, extra);
        } else {
            // eslint-disable-next-line no-console
            console.warn(message, ` | at ${logger}`);
        }
    }
};

export const reportIfEmpty = (
    data: string | string[] | Record<string, any> | Record<string, any>[] | null | null[],
    logger: string,
): void => {
    const level = Sentry.Severity.Error;

    if (Array.isArray(data)) {
        const message = 'Unexpected null or undefined in array! Possible ungraceful fallback';

        if (data.some((elem: string | Record<string, any> | null) => !elem)) {
            Sentry.captureEvent({ message, extra: { logger }, level });
            if (!IS_DOCKER) {
                // eslint-disable-next-line no-console
                console.warn(message, logger);
            }
        }
    } else {
        const message = 'Unexpected null or undefined! Possible ungraceful fallback';

        if (!data) {
            Sentry.captureEvent({ message, extra: { logger }, level });
            if (!IS_DOCKER) {
                // eslint-disable-next-line no-console
                console.warn(message, logger);
            }
        }
    }
};
