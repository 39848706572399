import { Layout } from 'antd';
import styled from 'styled-components';

import AdvertiserLayout from '@mp-ads/core/common/components/AdvertiserLayout';

import FooterBar from './Footer';
import NavigationBar from './Header';

const { Content } = Layout;

type TProps = {
    children: any;
};

const AppLayout = ({ children }: TProps) => (
    <AdvertiserLayout>
        <>
            <NavigationBar />
            <CustomContent>{children}</CustomContent>
            <FooterBar />
        </>
    </AdvertiserLayout>
);

const CustomContent = styled(Content)`
    padding: 0px 20px;
    background: #fff;
    @media (max-width: 991px) {
        padding: 0px 2%;
    }
    @media (min-width: 1499px) {
        padding: 0px 5%;
    }
    @media (min-width: 1600px) {
        padding: 0px 10%;
    }
`;
export default AppLayout;
