/* [DISABLED_TEMPORARILY] @ typescript-eslint /camelcase */
import { GoogleSpreadsheet } from 'google-spreadsheet';

import { initializeNAT } from '@mp-ads/core/utils/analytics';

let isArabic;
const sheetConfig = !process.env.IS_DEPLOYED_ON_PROD
    ? {
          worksheetID: '1tAqqFqFMocfFEzyU_T7YRAMX7D61z7My6KY49MPblBA',
          sheetID: 650210241,
      }
    : {
          worksheetID: '1f6vpzPCuKftnWO_YHQ6aHDKV_hqBE5afqtAPfBF50V8',
          sheetID: 691390419,
      };

const GDOC = process.env.GDOC_API_KEY;
const creds = {
    client_email: 'google-doc@noon-staging.iam.gserviceaccount.com',
    // eslint-disable-next-line no-underscore-dangle
    private_key: `${GDOC && GDOC.replace(/\\n/g, '\n')}`,
};

const doc = new GoogleSpreadsheet(sheetConfig.worksheetID);

export const register = async (payload: any): Promise<any> => {
    try {
        const timeStamp = new Date().toISOString();

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();
        const registerSheet = await doc.sheetsById[sheetConfig.sheetID];
        await registerSheet.addRow({ ...payload, timeStamp });

        return { message: 'ok', status: 200 };
    } catch (error) {
        return { status: 400, error: 'Something went wrong' };
    }
};

const getLayoutDirection = (direction: 'ltr' | 'rtl') => {
    isArabic = direction === 'rtl';
    return isArabic;
};

export const appInit = (router: any): 'ltr' | 'rtl' => {
    // eslint-disable-next-line no-nested-ternary
    const isRTL = router && router.locale.startsWith('en') ? 'ltr' : 'rtl';
    const lang = router.locale || 'en';
    getLayoutDirection(isRTL);
    initializeNAT(lang, 'ad_site');
    return isRTL || 'ltr';
};

export const getDirection = () => isArabic;

export const changeLanguage = (router) => {
    const lang = router.locale.startsWith('en') ? 'ar' : 'en';
    const { pathname } = router;
    setTimeout(() => {
        if (router.locale.startsWith('en' || 'ar')) {
            window.location.href = `${origin}/${lang}${pathname}`;
        } else {
            window.location.href = `${origin}/${lang}${pathname}`;
        }
    }, 100);
};
