import React, { useState } from 'react';

import styled from 'styled-components';
import Link from 'next/link';
import { Menu, Drawer, Button, Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { recordToAdtechCom } from '@mp-ads/core/utils/analytics';

import useWindowSize from '../hooks/useWindowSize';
import { changeLanguage, getDirection } from '../utils';

const { Header } = Layout;

const NavigationBar = (): JSX.Element => {
    const router = useRouter();
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation('advertise');
    const isRTL = getDirection();

    const handChangeLanguage = () => {
        changeLanguage(router);
    };
    const windowSizes = {
        mobileSize: 768,
    };
    const { isMobile } = useWindowSize(windowSizes);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onClicKBack = () => {
        router.push(`/`);
    };
    const handleNaviagtion = (pathName: string) => {
        onClose();
        handleNatAction(pathName);
        router.push(`/${pathName}`);
    };

    const handleNatAction = (pathName: string) => {
        recordToAdtechCom({ ev: `hd_${pathName}` });
    };

    return (
        <>
            {isMobile && (
                <CustomHeader
                    style={{
                        position: 'sticky',
                        top: 0,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LineFrame>
                        <Link href="/" onClick={() => handleNatAction('home_logo')}>
                            <AdsLogo loading="lazy" srcSet="/icons/noon-ads-logo.png" />
                        </Link>
                        <div style={{ textAlign: 'right' }}>
                            <CustomButton onClick={showDrawer}>
                                <MenuOutlined />
                            </CustomButton>
                            <CustomDrawer
                                placement={isRTL ? 'left' : 'right'}
                                onClose={onClose}
                                open={visible}
                                style={{
                                    height: '440px',
                                    borderRadius: '10px 0px 10px 10px',
                                    boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.15)',
                                }}
                            >
                                <Menu mode="vertical">
                                    <Menu.Item key="1" onClick={() => handleNaviagtion('sellers')}>
                                        {t('sellers')}
                                    </Menu.Item>
                                    <Menu.Item key="2" onClick={() => handleNaviagtion('brands')}>
                                        {t('brands')}
                                    </Menu.Item>
                                    <Menu.Item key="3" onClick={() => handleNaviagtion('advertisers')}>
                                        {t('advertisers')}
                                    </Menu.Item>
                                    <Menu.Item key="4" onClick={() => handleNaviagtion('request')}>
                                        {t('contact_us')}
                                    </Menu.Item>
                                    <Menu.Item key="4">
                                        <Link
                                            onClick={() => handleNatAction('start_now')}
                                            href="https://admanager.noon.partners/en-ae/?utm_source=ad_site&utm_medium=header"
                                            passHref
                                            target="_blank"
                                        >
                                            <ContactUs>{t('start_now')}</ContactUs>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Languages type="text" onClick={handChangeLanguage}>
                                            {t('language')}
                                        </Languages>
                                    </Menu.Item>
                                </Menu>
                            </CustomDrawer>
                        </div>
                    </LineFrame>
                </CustomHeader>
            )}
            {!isMobile && (
                <CustomHeader
                    style={{
                        position: 'sticky',
                        top: 0,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        height: 'auto',
                    }}
                >
                    <Div2>
                        <Div3>
                            <LinkWrapper>
                                <Link onClick={() => handleNatAction('sellers')} href="/sellers" passHref>
                                    {t('sellers')}
                                </Link>
                            </LinkWrapper>
                            <LinkWrapper>
                                <Link onClick={() => handleNatAction('brands')} href="/brands" passHref>
                                    {t('brands')}
                                </Link>
                            </LinkWrapper>
                            <LinkWrapper>
                                <Link onClick={() => handleNatAction('advertisers')} href="/advertisers" passHref>
                                    {t('advertisers')}
                                </Link>
                            </LinkWrapper>
                        </Div3>
                        <Div7>
                            <Link href="/" onClick={() => handleNatAction('home_logo')}>
                                <AdsLogo loading="lazy" srcSet="/icons/noon-ads-logo.png" />
                            </Link>
                        </Div7>
                        <Div8>
                            <Div9>
                                <Link
                                    onClick={() => handleNatAction('start_now')}
                                    href="https://admanager.noon.partners/en-ae?utm_source=ad_site&utm_medium=header"
                                    passHref
                                    target="_blank"
                                >
                                    {t('start_now')}
                                </Link>
                            </Div9>
                            <LinkWrapper>
                                <Link href="/request" passHref onClick={() => handleNatAction('contact us')}>
                                    {t('contact_us')}
                                </Link>
                            </LinkWrapper>
                            <Languages type="text" onClick={handChangeLanguage}>
                                {t('language')}
                            </Languages>
                        </Div8>
                    </Div2>
                </CustomHeader>
            )}
        </>
    );
};

export default NavigationBar;

const CustomDrawer = styled(Drawer)`
    color: var(--Gray-700, #272d37);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
    .ant-drawer-header {
        border: none;
    }
    .ant-drawer-header-title {
        justify-content: flex-end;
    }
    .ant-menu-item {
        display: flex;
        justify-content: flex-end;
        height: 60px;
        position: relative;
        bottom: 15px;
        font-size: 15px;
        color: var(--Gray-700, #272d37);
        font-weight: 600;
    }
    .ant-drawer-close {
        color: #000000;
        font-size: 24px;
    }
    .ant-menu-item-selected {
        background-color: #ffffff;
    }
`;

const CustomHeader = styled(Header)`
    background-color: #fff;
    z-index: 555;
    padding: 0px 0px; /* for noon ads logo */
    a {
        display: flex;
    }
`;

const CustomButton = styled(Button)`
    background-color: transparent;
`;

const LineFrame = styled.div`
    width: 87%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    z-index: 1;
    @media screen and (max-width: 991px) {
        width: 747px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
`;

const Div2 = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    font-size: 15px;
    font-weight: 600;
    line-height: 147%;
    justify-content: space-between;
    padding: 25px 12%;
    @media (max-width: 991px) {
        max-width: 100%;
        flex-wrap: wrap;
    }
    @media screen and (max-width: 930px) {
        padding: 25px 5%;
        gap: 0px;
    }
`;
const Div3 = styled.div`
    display: flex;
    gap: 20px;
    white-space: nowrap;
    justify-content: space-between;
    margin: auto 0;
    @media (max-width: 991px) {
        white-space: initial;
    }
    a {
        color: var(--Gray-700, #272d37);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 146.667% */
    }
`;
const LinkWrapper = styled.div`
    :hover {
        color: #272626;
        text-decoration: underline;
    }
    a {
        color: var(--Gray-700, #272d37);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 146.667% */
    }
`;

const Div7 = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: 991px) {
        max-width: 100%;
        flex-wrap: wrap;
    }
`;
const AdsLogo = styled.img`
    /* aspect-ratio: 4.35; */
    /* object-fit: auto; */
    object-position: center;
    width: 150px;
    max-width: 100%;
    margin: auto 0;
`;
const Div8 = styled.div`
    justify-content: end;
    display: flex;
    padding-right: 20px;
    gap: 20px;
    align-items: center;
`;
const Div9 = styled.div`
    justify-content: center;
    border-radius: 321px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    background-color: #060606;
    color: #f3e008;
    padding: 10px 16px;
    cursor: pointer;
    :hover {
        background: linear-gradient(to right, rgb(50, 104, 248), rgb(100, 74, 255));
    }
    a {
        color: #f3e008;
        /* Text Button/Semibold Large */
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 146.667% */
    }
`;

const ContactUs = styled.div`
    justify-content: center;
    border-radius: 321px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    background-color: #060606;
    color: #f3e008;
    padding: 2px 20px;
    cursor: pointer;
    margin-top: 2px;
    :hover {
        background: linear-gradient(to right, rgb(50, 104, 248), rgb(100, 74, 255));
    }
`;

const Languages = styled(Button)`
    color: #0008be;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px; /* 146.667% */
    :hover {
        text-decoration: underline;
    }
`;
