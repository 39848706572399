import React from 'react';

import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

import { recordToAdtechCom } from '@mp-ads/core/utils/analytics';

const FooterBar = (): JSX.Element => {
    const { t } = useTranslation('advertise');
    const handleNatAction = (pathName: string) => {
        recordToAdtechCom({ ev: pathName });
    };

    return (
        <CustomFooter>
            <Navbar1>
                <AdvertisingServices>
                    <CopyrightInfo>{t('noon_copyright')}</CopyrightInfo>
                    {/* <CopyrightInfo>
                    <LinkedinIcon loading="eager" alt="" src="/icons/linkedin.svg" />
                    <LinkedinIcon loading="eager" alt="" src="/icons/insta.svg" />
                    <LinkedinIcon loading="eager" alt="" src="/icons/x.svg" />
                    <LinkedinIcon loading="eager" alt="" src="/icons/youtube.svg" />
                </CopyrightInfo> */}
                    <TermsOfUseTermsOfSalePri>
                        <TermsOfUse
                            onClick={() => handleNatAction('sell_with_us')}
                            href="https://sell.withnoon.com/home-ae-en"
                            target="_blank"
                        >
                            {t('sell_with_us')}
                        </TermsOfUse>
                        <TermsOfUse
                            onClick={() => handleNatAction('terms_of_use')}
                            href="https://www.noon.com/uae-en/terms-of-use/"
                            target="_blank"
                        >
                            {t('terms_of_use')}
                        </TermsOfUse>
                        <TermsOfUse
                            onClick={() => handleNatAction('terms_of_sale')}
                            href="https://www.noon.com/uae-en/terms-of-sale/"
                            target="_blank"
                        >
                            {t('terms_of_sale')}
                        </TermsOfUse>
                        <TermsOfUse
                            onClick={() => handleNatAction('privacy_policy')}
                            href="https://www.noon.com/uae-en/privacy-policy/"
                            target="_blank"
                        >
                            {t('privacy_policy')}
                        </TermsOfUse>
                    </TermsOfUseTermsOfSalePri>
                </AdvertisingServices>
            </Navbar1>
        </CustomFooter>
    );
};

export default FooterBar;

const CustomFooter = styled.div`
    background-color: white;
`;
const LinkedinIcon = styled.img`
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
`;
const CopyrightInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    color: #404553;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 450px) {
        flex-wrap: wrap;
        display: flex;
        width: 100%;
        justify-content: center;
    }
`;
const TermsOfUse = styled.a`
    flex: 1;
    position: relative;
    color: inherit;
    display: inline-block;
    text-decoration: none;
    min-width: 110px;
    color: #404553;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    :hover {
        text-decoration: underline;
    }
`;
const TermsOfUseTermsOfSalePri = styled.div`
    width: 550px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
    @media screen and (max-width: 450px) {
        flex-wrap: wrap;
        display: flex;
        width: 100%;
        justify-content: center;
    }
`;
const AdvertisingServices = styled.div`
    width: 1142px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    color: #404553;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    z-index: 1;
    @media screen and (max-width: 1050px) {
        flex-wrap: wrap;
    }
`;
const Navbar1 = styled.footer`
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 70px 28px 28px 28px;
    box-sizing: border-box;
    max-width: 100%;
    text-align: center;
    font-size: 16px;
    color: #404553;
    @media (max-width: 991px) {
        padding: 28px;
    }
`;
