import { platform } from 'os';

import { v4 as uuid } from 'uuid'; // DO NOT CHANGE. Check with @Nipun, @Gihan or Watchdog SVC for used version
import { sdk as natSDK } from 'nat-sdk-web';
import Cookies from 'universal-cookie';

import { reportError } from './sentry';
import { getLocale } from './locale';

export const IS_BROWSER = typeof window !== 'undefined';
export const getCookie = (key: string): string | null => {
    const cookie = new Cookies();
    return cookie.get(key);
};

// eslint-disable-next-line import/no-mutable-exports
export let visitorId: string | null = null;

export const initializeVisitorId = (): void => {
    visitorId = getCookie('visitor_id');
    if (!visitorId) {
        visitorId = uuid();
        setCookie('visitor_id', visitorId);
    }
};
export const setCookie = (key: string, value: string): void => {
    const expires = new Date('Fri, 31 Dec 9999 12:00:00 GMT');

    const isLocalhost = window.location.hostname === 'localhost';

    let hostname;
    if (isLocalhost) {
        hostname = 'localhost';
    } else {
        const stgSuffix = window.location.hostname.split('.')[1].split('noon')[1] || '';
        hostname = `.${'noon'}${stgSuffix}.partner`;
    }

    const cookie = new Cookies();
    cookie.set(key, value, { expires, secure: true, path: '/', domain: hostname });
};

// app_name == marketplace experience
export const initializeNAT = async (lang?: string, app_name = 'seller_listing_ads'): Promise<void> => {
    if (!IS_BROWSER) return;
    const apiLocale = getLocale()?.apiLocale || lang;
    initializeVisitorId();
    if (natSDK?.initialized) return;
    try {
        natSDK.init(process.env.natUrl, 'noon', visitorId, apiLocale, 'web', null);
        natSDK.updateLocale(apiLocale);
        switch (app_name) {
            case 'ad_site':
                recordToAdtechCom({ ev: 'launched' });
                break;
            default:
                trackPage({ ev: 'launch' });
                break;
        }
    } catch (error) {
        // reportError(error as Error, { componentStack: 'NAT SDK INIT' });
    }
};

const recordToNAT = (eventPage?: any): void => {
    try {
        if (!natSDK.initialized) {
            initializeNAT();
            return;
        }
        const data = {
            mp: 'seller_lab',
            mpe: 'seller_listing_ads',
            device: 'desktop',
            ...eventPage,
        } as const;
        natSDK.track(data.ev, data);
    } catch (error) {
        reportError(error as Error, { componentStack: 'Track Pages' });
    }
};

export const trackPage = (pageEvent: any): void => {
    try {
        recordToNAT(pageEvent);
    } catch (error) {
        reportError(error as Error, { componentStack: 'Track Pages' });
    }
};

export const trackAction = (pageEvent: any): void => {
    try {
        recordToNAT(pageEvent);
    } catch (error) {
        reportError(error as Error, { componentStack: 'Track clicked action' });
    }
};

export const recordToAdtechCom = (pageEvent: any): void => {
    try {
        if (!natSDK.initialized) {
            initializeNAT();
            return;
        }

        const data = {
            mp: 'seller_lab',
            mpe: 'ad_site',
            device: 'desktop',
            ...pageEvent,
        } as const;

        natSDK.track(data.ev, data);
    } catch (error) {
        reportError(error as Error, { componentStack: 'Track Pages' });
    }
};
