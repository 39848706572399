import type { ThemeConfig } from 'antd';

const Theme = {
    token: {
        fontFamily: 'Plus Jakarta Sans, Cairo',
    },
    components: {
        section: {
            backgroundColor: '#F2F3F8',
        },
        body: {
            background: '#FFFFFF',
        },
        Collapse: {
            headerBg: '#FAFBFF',
            headerPadding: 20,
            contentBg: '#FAFBFF',
        },
    },
    inherit: false,
} as ThemeConfig;

export default Theme;
