import { Layout } from 'antd';
import styled from 'styled-components';

type TProp = {
    children: React.ReactNode;
};
const AdvertiserLayout = ({ children }: TProp): JSX.Element => <CustomeLayout>{children}</CustomeLayout>;

const CustomeLayout = styled(Layout)``;

export default AdvertiserLayout;
