import { i18n } from 'next-i18next';
import dayjs from 'dayjs';

import { TAppDetails } from '@mp-ads/product-ads/src/types/admanager';

let locale: any;

const PLURAL_CONFIG = [
    {
        lngs: ['en-ae', 'en-sa', 'en-eg'],
        numbers: [1, 2],
        pluralFn: (n: number) => Number(n !== 1),
    },
    {
        lngs: ['ar-ae', 'ar-sa', 'ar-eg'],
        numbers: [0, 1, 2, 3, 11, 100],
        pluralFn: (n: number) =>
            // eslint-disable-next-line no-nested-ternary
            Number(n === 0 ? 0 : n === 1 ? 1 : n === 2 ? 2 : n % 100 >= 3 && n % 100 <= 10 ? 3 : n % 100 >= 11 ? 4 : 5),
    },
];
// loc: locale
export const localeInit = (loc: string): void => {
    if (i18n) {
        PLURAL_CONFIG.forEach((entry) => {
            entry.lngs.forEach((lng) => {
                i18n?.services.pluralResolver.addRule(lng, {
                    numbers: entry.numbers,
                    plurals: entry.pluralFn,
                });
            });
        });
    }

    locale = {
        apiLocale: loc,
        isUAE: loc.endsWith('ae'),
        isSaudi: loc.endsWith('sa'),
        isEgypt: loc.endsWith('eg'),
        isRTL: loc.startsWith('ar'),
        isrtl: loc.startsWith('ar'),
        currency: getCurrency(loc),
    };
};

export const getCurrency = (loc: string): string => {
    if (loc?.endsWith('ae')) {
        return 'AED';
    }
    if (loc?.endsWith('sa')) {
        return 'SAR';
    }
    return 'EGP';
};

export const getCountryCode = (loc: string) => {
    switch (loc) {
        case 'en-sa':
            return 'saudi-en';
        case 'ar-sa':
            return 'saudi-ar';
        case 'en-eg':
            return 'egypt-en';
        case 'ar-eg':
            return 'egypt-ar';
        case 'ar-en':
            return 'uae-ar';
        default:
            return 'uae-en';
    }
};
export const setCountry = (loc: string, country: string) => {
    try {
        const lang = loc.split('-')[0];
        const { pathname } = window.location;
        setTimeout(() => {
            if (pathname === '/' || pathname.split(`${country}`).length === 1) {
                window.location.href = `/${country.replace('en', lang)}`;
            } else if (pathname.includes('campaign')) {
                const path: string[] = pathname.split('/campaign');
                const nextPath = path[path.length - 1];
                window.location.href = `/${country.replace('en', lang)}/campaign${nextPath}`;
            } else {
                window.location.href = `/${country.replace('en', lang)}`;
            }
        }, 100);
    } catch (error) {
        console.log('==>>>country', error);
    }
};

export const setLanguage = (loc: string) => {
    try {
        const isRTL = loc.startsWith('ar');
        const region = loc.split('-')[1];
        const locales = `${!isRTL ? 'ar' : 'en'}-${region}`;
        dayjs.locale(isRTL ? 'ar' : 'en'); // for antd Datetime picker rtl
        const { pathname } = window.location;
        setTimeout(() => {
            if (pathname.split(`/campaign`).length >= 2) {
                const path: string[] = pathname.split('/campaign');
                const nextPath = path[path.length - 1];
                window.location.href = `/${locales}/campaign${nextPath}`;
            } else {
                window.location.href = `/${locales}/`;
            }
        }, 100);
    } catch (error) {
        console.log('==>>>', error);
    }
};

export const getLocale = (): any => locale;

export const numberFormatter = (value: any, symbol?: string, style?: string | number, currency?: string): string => {
    const locales = getLocale();
    if (symbol === 'unit') {
        if (style && typeof style === 'number') {
            return Intl.NumberFormat(undefined, {
                minimumFractionDigits: style,
            }).format(value || 0);
        }
        return Intl.NumberFormat().format(value);
    }

    if (symbol === '%' || style) {
        return Intl.NumberFormat(undefined, {
            style: 'percent',
            minimumFractionDigits: 2,
        }).format(value / 100 || 0);
    }
    // Fixing for Display dashboard
    const currencyLocale = currency === 'USD' ? 'en' : locales?.apiLocale || 'en';
    return new Intl.NumberFormat(currencyLocale, {
        style: 'currency',
        currency: currency || locales?.currency || 'USD',
    }).format(value || 0);
};

export const numberFormatterV2 = (value: any, symbol?: string, currency?: string, style?: string | number): string => {
    const locales = getLocale();

    if (symbol === 'unit') {
        const options: Intl.NumberFormatOptions =
            style && typeof style === 'number' ? { minimumFractionDigits: style } : {};
        return Intl.NumberFormat(undefined, options).format(value || 0);
    }

    if (symbol === '%' || style) {
        const percentOptions: Intl.NumberFormatOptions = { style: 'percent', minimumFractionDigits: 2 };
        return Intl.NumberFormat(undefined, percentOptions).format((value || 0) / 100);
    }

    const currencyLocale = currency ?? locales?.currency;

    const currencyOptions: Intl.NumberFormatOptions = { style: 'currency', currency: currencyLocale };
    return new Intl.NumberFormat(currencyLocale, currencyOptions).format(value || 0);
};

export const numberFormatterUSD = (value: any, symbol?: string): string => {
    const numericValue = typeof value === 'number' ? value : parseFloat(value);

    return new Intl.NumberFormat('en', {
        style: 'currency',
        currency: symbol || 'USD',
    }).format(numericValue || 0);
};

export const round = (value: number, precision: number): number => {
    const multiplier = 10 ** (precision || 0);
    return Math.floor(value * multiplier) / multiplier;
};

export const getQueryValue = (key: string): string | null => {
    if (process.browser) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }
    return null;
};

export const getProjectQuery = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams && urlParams.get('project');
};

export const getProjectQueryV2 = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams && urlParams.get('project');
    return `?project=${project_id}`;
};

export const setURLLocale = (userLocales: string[], appDetails: TAppDetails, query: any, pathname: string) => {
    const pathnameHasLocale = window.location.href.match(new RegExp(`/(en|ar)-(${userLocales.join('|')})`, 'gim'));

    if (!pathnameHasLocale && appDetails?.countries) {
        const queryParamKeys = Object.keys(query);
        const queryParams = queryParamKeys.map((key) => `${key}=${query[key]}`);

        let newPathname = `/en-${userLocales[0]}${pathname}`;

        // if b2b and if its ladning on /, we redirect user to /order
        if (!pathname.match(/order/gim) && appDetails?.invoicingType === 'b2b' && !appDetails?.hasCreatedOrders) {
            newPathname = `${newPathname.replace(/\/$/, '')}/order`;
        }
        newPathname = `${newPathname}?${queryParams.join('&')}`;

        window.history.replaceState(null, '', newPathname);
        window.location.href = newPathname;
    }
};
