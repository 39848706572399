import { useState, useEffect, useCallback } from 'react';

type TProps = {
    mobileSize: number;
    miniTabletSize?: number;
    tabletSize?: number;
};

type TWindowSize = {
    isMobile: boolean;
    isTablet?: boolean;
    isMiniTablet?: boolean;
};

const useWindowSize = ({ miniTabletSize, mobileSize, tabletSize }: TProps): TWindowSize => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isMiniTablet, setIsMiniTablet] = useState(false);

    const handleResize = useCallback(() => {
        setIsMobile(window.innerWidth <= mobileSize);
        setIsTablet(window.innerWidth <= tabletSize);
        setIsMiniTablet(window.innerWidth <= miniTabletSize);
    }, [mobileSize, tabletSize, miniTabletSize]);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return { isMobile, isTablet, isMiniTablet };
};

export default useWindowSize;
